import React from "react";
import {
  fileurl,
  TableGeneral,
  ThGeneral,
  TdGeneral,
  SpanBGeneral,
  EditPenIcon,
  EditPenGreyIcon,
  DocOpenIcon,
  TrackingIcon,
} from "../../configurations/general-conf";
import moment from "moment";
import {
  getOrderStatusColor,
  getOrderTypes,
  getOrderStatus,
  getSubStatusColor,
  getReturnOrderStatus,
  getReturnOrderStatusColor,
  getDyPath,
  getCatsFromProducts,
} from "../widgets/utilities";
import editPenIcon from "../../resources/icons/edit_pen.svg";
import editPenGreyIcon from "../../resources/icons/edit_pen_grey.svg";
import delIcon from "../../resources/icons/delete.svg";
import docIcon from "../../resources/icons/doc.svg";
import trackingIcon from "../../resources/icons/tracking.svg";
import store from "../../store";

const DataTableView = (props) => {
  // console.log(props);

  const { list, tableCfg, toEdit, toDel, toTrack } = props;
  return (
    <>
      {list?.length > 0 ? (
        <TableGeneral>
          <thead>
            <tr>
              {tableCfg?.columns?.map((column, i) => {
                return (
                  <ThGeneral key={`header${i}`} textalign={column.textAlign}>
                    {column.title}
                  </ThGeneral>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {list.map((listItem, idx) => {
              return (
                <tr key={listItem[tableCfg.mainKey]}>
                  {tableCfg.columns.map((column, i) => {
                    const key = `${listItem[tableCfg.mainKey]}_data_${i}`;
                    switch (column.type) {
                      case "localTime":
                        const iTime =
                          listItem[column.key] === null
                            ? "TBD"
                            : moment(listItem[column.key]).format(
                                column.format
                              );
                        return (
                          <TdGeneral key={key} textalign={column.textAlign}>
                            {iTime}
                          </TdGeneral>
                        );
                      case "leftTime":
                        const iTimeDiff = moment.duration(
                          moment().diff(listItem[column.key])
                        );
                        // console.log(iTimeDiff);
                        const iHours =
                          48 - (iTimeDiff.days() * 24 + iTimeDiff.hours());
                        const iHoursTD = iHours >= 0 ? iHours : 0;
                        return (
                          <TdGeneral key={key} textalign={column.textAlign}>
                            {iHoursTD}h
                          </TdGeneral>
                        );
                      case "validTime":
                        const iTime2 = moment(listItem[column.key]).format(
                          column.format
                        );
                        const iTimeDiff2 = moment.duration(
                          moment(listItem[column.key]).diff(moment())
                        );
                        return (
                          <TdGeneral
                            key={key}
                            textalign={column.textAlign}
                            xcolor={
                              iTimeDiff2.days() >= 0 ? "passGreen" : "noticeRed"
                            }
                          >
                            {iTime2}
                          </TdGeneral>
                        );
                      case "orderStatus":
                        return (
                          <TdGeneral
                            key={key}
                            textalign={column.textAlign}
                            xcolor={getOrderStatusColor(listItem[column.key])}
                          >
                            {/* {getOrderStatus(
                              listItem[column.key],
                              listItem[column.subKey]
                            )} */}
                            <SpanBGeneral>
                              {getOrderTypes(listItem[column.key])}
                            </SpanBGeneral>
                          </TdGeneral>
                        );
                      case "orderSubStatus":
                        return (
                          <TdGeneral
                            key={key}
                            textalign={column.textAlign}
                            xcolor={getSubStatusColor(
                              listItem[column.key],
                              listItem[column.subKey]
                            )}
                          >
                            <SpanBGeneral>
                              {getOrderStatus(
                                listItem[column.key],
                                listItem[column.subKey]
                              )}
                            </SpanBGeneral>
                          </TdGeneral>
                        );
                      case "returnStatus":
                        return (
                          <TdGeneral
                            key={key}
                            textalign={column.textAlign}
                            xcolor={getReturnOrderStatusColor(
                              listItem[column.key]
                            )}
                          >
                            <SpanBGeneral>
                              {getReturnOrderStatus(listItem[column.key])}
                            </SpanBGeneral>
                          </TdGeneral>
                        );
                      case "completed":
                        return (
                          <TdGeneral
                            key={key}
                            textalign={column.textAlign}
                            xcolor="passGreen"
                          >
                            <SpanBGeneral>Completed</SpanBGeneral>
                          </TdGeneral>
                        );
                      case "yesNo":
                        return (
                          <TdGeneral
                            key={key}
                            textalign={column.textAlign}
                            xcolor={
                              listItem[column.key]
                                ? "passGreen"
                                : "warningYellow"
                            }
                          >
                            {listItem[column.key] ? "Yes" : "No"}
                          </TdGeneral>
                        );
                      case "yesNo_2":
                        return (
                          <TdGeneral
                            key={key}
                            textalign={column.textAlign}
                            xcolor={
                              listItem[column.key] > 1
                                ? "passGreen"
                                : "warningYellow"
                            }
                          >
                            {listItem[column.key] > 1 ? "Yes" : "No"}
                          </TdGeneral>
                        );
                      case "catsFromProducts":
                        const cats = getCatsFromProducts(listItem[column.key], column.alias);
                        return (
                          <TdGeneral key={key} textalign={column.textAlign}>
                            {cats.map((cat, i) => {
                              return (
                                <div key={i}>{cat.productCategoryName}</div>
                              );
                            })}
                          </TdGeneral>
                        );
                      case "edit":
                        let allowEdit = true;
                        let displayIcon = true;
                        if (column.limitKey) {
                          const allowIndex = column.allowed.findIndex(
                            (obj) => obj === listItem[column.limitKey]
                          );
                          allowEdit = allowIndex > -1;
                          if (column.repAllowed && store.getState()["company"]?.repLogin) {
                            const repAllowIndex = column.repAllowed.findIndex(
                              (obj) => obj === listItem[column.limitKey]
                            );
                            allowEdit = repAllowIndex > -1;
                            if (column.furtherTestValue && listItem[column.limitKey]===column.furtherTestValue) {
                              const furtherAllowIndex = column.furtherAllowed.findIndex(
                                (obj) => obj === listItem[column.furtherLimitKey]
                              );
                              allowEdit = furtherAllowIndex > -1;
                            }
                          }
                          if (allowEdit && column.furtherLimitUser) {
                            allowEdit = column.furtherLimitUser.includes(store.getState()["company"]?.repLogin);
                          }
                        }
                        displayIcon = column.hiddenIcon ? (column.hiddenIcon && allowEdit) : true;
                        return (
                          displayIcon && <TdGeneral
                            key={key}
                            xwidth={window.innerWidth > 640 ? 18 : 24}
                          >
                            {allowEdit ? (
                              <EditPenIcon
                                src={editPenIcon}
                                onClick={() => {
                                  toEdit(
                                    listItem[tableCfg.mainKey],
                                    getDyPath(
                                      listItem[column.limitKey],
                                      column.subType
                                    )
                                  );
                                }}
                              />
                            ) : (
                              <EditPenGreyIcon src={editPenGreyIcon} />
                            )}
                          </TdGeneral>
                        );
                      case "delete":
                        let allowDel = true;
                        if (column.limitKey) {
                          const allowIndex = column.allowed.findIndex(
                            (obj) => obj === listItem[column.limitKey]
                          );
                          allowDel = allowIndex > -1;
                        }
                        if(allowDel && column.repOnly){
                          allowDel = store.getState()["company"]["repLogin"] ? true : false;
                        }
                        // allowDel = column.repOnly ? (store.getState()["company"]["repLogin"] ? true : false) : true;
                        return (
                          allowDel && (
                            <TdGeneral
                              key={key}
                              xwidth={window.innerWidth > 640 ? 16 : 22}
                            >
                              {allowDel && (
                                <EditPenIcon
                                  src={delIcon}
                                  onClick={() => {
                                    toDel(
                                      listItem[tableCfg.mainKey],
                                      listItem[column.limitKey],
                                      column.delName
                                    );
                                  }}
                                />
                              )}
                            </TdGeneral>
                          )
                        );
                      case "doc":
                        let allowDoc = true;
                        if (column.limitKey) {
                          const allowIndex = column.allowed.findIndex(
                            (obj) => obj === listItem[column.limitKey]
                          );
                          allowDoc = allowIndex > -1;
                        }
                        // if (column.limitKey2) {
                        //   allowDoc = listItem[column.limitKey2] ? true : false;
                        // }
                        return (
                          allowDoc && (
                            <TdGeneral
                              key={key}
                              xwidth={window.innerWidth > 640 ? 16 : 22}
                            >
                              {listItem[column.urlKey] && (
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={fileurl + "/" + listItem[column.urlKey]}
                                >
                                  <DocOpenIcon src={docIcon} />
                                </a>
                              )}
                            </TdGeneral>
                          )
                        );
                      case "tracking":
                        return (
                          <TdGeneral
                            key={`${listItem[column.key]}_${idx}`}
                            textalign={column.textAlign}
                          >
                            {listItem[column.key] && (
                              <TrackingIcon
                                src={trackingIcon}
                                onClick={() => {
                                  toTrack(
                                    listItem[column.key],
                                    listItem[column.subKey]
                                  );
                                }}
                              />
                            )}
                          </TdGeneral>
                        );
                      default:
                        let iValue =
                          listItem[column.key] === null
                            ? ""
                            : listItem[column.key];
                        column.prefix && (iValue = column.prefix + iValue);
                        return (
                          <TdGeneral key={key} textalign={column.textAlign}>
                            {iValue}
                          </TdGeneral>
                        );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </TableGeneral>
      ) : (
        <SpanBGeneral xfontSize="14">
          {tableCfg?.noDataInfo ? tableCfg?.noDataInfo : "No Data."}
        </SpanBGeneral>
      )}
    </>
  );
};

export default DataTableView;
